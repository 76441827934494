@use './stars.scss';
@use './slider.scss';
@use './border.scss';
@use './ribbon.scss';

@tailwind base;

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Start purging... */
@tailwind utilities;
@tailwind variants;
/* Stop purging. */

/* Your own custom utilities */
#__next {
  height: 100vh;
}

html {
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
  ::-webkit-scrollbar-track {
    background: #333333; 
    border-radius: 5px;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover  {
    background: #5C5C5C;
    border-radius: 5px;
  } 
}

body {
  @apply font-roboto tracking-1.4;
}

// Additional resets
button {
  outline: none;
  &:active, &:focus, &:visited, &:hover {
    outline: none;
    &:active, &:focus, &:visited, &:hover {
      outline: none;
    }
  }
}

@layer utilities {
  .underline, [style*="underline"] {
    position: relative;
    text-decoration: none !important;

    &::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 0;
      right: 0;
      height: 5px;
      z-index: 1;
      @screen md {
        height: 7px;
      }

      background-image: url('/images/Underline.svg');
      background-repeat: no-repeat;

      background-size: cover;
    }
  }
  .traditional-underline {
    text-decoration: underline;
  }
  @variants responsive {
    .full {
      div{
        height: 100%;
        display: flex !important;
      }
    }
  }
}

@keyframes placeholder-pulse {
  0% {
    opacity: .6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .6;
  }
}

.animation {
  animation: react-placeholder-pulse 1.5s infinite;
}

.right-arrow {
  display: inline-block;
  width: 22px;
  height: 12px;
  background-image: url('/images/RightArrow.svg');
}

#reviews-link a {
  text-decoration: underline;
  color: #43b02a;
}

// styles for label in FOP
.bg-orange {
  background-color: #F7AC3F;
}

.bg-alto {
  background-color: #d4d4d4;
}

.bg-yellow {
  background-color: #ffff00;
}

.close-tooltip {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url('/images/close.svg');
  mask-size: cover;
  background-repeat: no-repeat;
}

.MuiTooltip-popperArrow[x-placement="top-start"] .MuiTooltip-arrow {
  left: 0 !important;
  margin-left: 20px !important;
}

.MuiTooltip-arrow {
  color: #58a4b0 !important;
}
.MuiTooltip-tooltipArrow {
  background-color: #58a4b0 !important;
  padding: 8px 15px !important;
  span {
    text-decoration: underline !important;
    &::after {
        background-image: none;
    }
  }
}

.MuiTooltip-tooltip {
  max-width: 220px !important;
  border-radius: 7px !important;
  @screen md {
    max-width: 276px !important;
  }
  button {
    display: flex !important;
    pointer-events: all !important;
    min-width: auto !important;
    padding: 0 0 0 10px !important;
  }
}

.sticky-content {
  z-index: 1501;
}

//cross mark closing button
.close {
  display: block;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M8 8 L24 24 M8 24 L24 8'/%3E%3C/svg%3E");
}


.height-fit-content {
  height: fit-content;
}
