.border-dashed {
  position: relative;

  --border-color: theme('colors.silver');
  --border-weight: 2px;
  --dash-size: 18px;
  --gap-size: 15px;
}

.border-dashed:after {
  content:'';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
      linear-gradient(90deg, var(--border-color) 100%, transparent 100%) top left no-repeat,
      linear-gradient(90deg, transparent calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)), transparent calc(var(--gap-size) / 2 + var(--dash-size))) top center repeat-x,
      linear-gradient(90deg, var(--border-color) 100%, transparent 100%) top right no-repeat,
      
      linear-gradient(0deg, var(--border-color) 100%, transparent 100%) top left no-repeat,
      linear-gradient(0deg, transparent calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)), transparent calc(var(--gap-size) / 2 + var(--dash-size))) center left repeat-y,
      linear-gradient(0deg, var(--border-color) 100%, transparent 100%) bottom left no-repeat,
      
      linear-gradient(90deg, var(--border-color) 100%, transparent 100%) bottom left no-repeat,
      linear-gradient(90deg, transparent calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)), transparent calc(var(--gap-size) / 2 + var(--dash-size))) bottom center repeat-x,
      linear-gradient(90deg, var(--border-color) 100%, transparent 100%) bottom right no-repeat,
      
      linear-gradient(0deg, var(--border-color) 100%, transparent 100%) top right no-repeat,
      linear-gradient(0deg, transparent calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2), var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)), transparent calc(var(--gap-size) / 2 + var(--dash-size))) center right repeat-y,
      linear-gradient(0deg, var(--border-color) 100%, transparent 100%) bottom right no-repeat;
  background-size: var(--dash-size) var(--border-weight), calc(var(--dash-size) + var(--gap-size)) var(--border-weight), var(--dash-size) var(--border-weight), var(--border-weight) var(--dash-size), var(--border-weight) calc(var(--dash-size) + var(--gap-size)), var(--border-weight) var(--dash-size);
}