// Variables
$light-blue: #58A4B0;
$primary-green: #43B02A;
//  Slider
.regular {
  .flickity-enabled {
    .flickity-button {
      svg.flickity-button-icon path {
        d: path('M 25,50 L 60,85 L 70,85 L 35,50  L 70,15 L 60,15 Z');
        @screen md {
          d: path('M 10,50 L 60,100 L 65,95 L 20,50  L 65,5 L 60,0 Z');
        }
      }
    }
    .flickity-button.flickity-prev-next-button {
      color: #70827D;
      &.previous {
        @screen md {
          left: 0;
        }
        left: -10px;
      }
      &.next {
        @screen md {
          right: 0;
        }
        right: -10px;

      }
      &.flickity-button-icon {
        top: -22%;
      }
    }
    .flickity-prev-next-button,
    .flickity-prev-next-button .previous,
    .flickity-prev-next-button .next,
    .flickity-prev-next-button:hover,
    .flickity-prev-next-button .previous:hover,
    .flickity-prev-next-button .next:hover {
      background-color: transparent;
    }

    .flickity-page-dots {
      bottom: -40px;
    }

    .flickity-page-dots li.dot {
      background-color: #70827D;
      &.is-selected {
        border-radius: 6px;
        width: 24px;
        height: 12px;
      }
    }
  }
}

.custom-white, .custom-dark {
  .flickity-enabled {
    .flickity-button {
      svg.flickity-button-icon path {
        d: path('M 10,50 L 60,100 L 65,95 L 20,50  L 65,5 L 60,0 Z');
        @screen md {
          d: path('M 10,50 L 60,100 L 65,95 L 20,50  L 65,5 L 60,0 Z');
        }
      }
    }
    .flickity-button.flickity-prev-next-button {
      height: 50px;
      width: 50px;
      @screen mx-sm {
        height: 30px;
        width: 30px;
      }
      &.previous {
        @screen md {
          left: -6px;
        }
        left: -4px;
      }
      &.next {
        @screen md {
          right: -6px;
        }
        right: -4px;

      }
      &.flickity-button-icon {
        top: -22%;
      }
    }
    .flickity-prev-next-button,
    .flickity-prev-next-button .previous,
    .flickity-prev-next-button .next,
    .flickity-prev-next-button:hover,
    .flickity-prev-next-button .previous:hover,
    .flickity-prev-next-button .next:hover {
      background-color: transparent;
    }

    .flickity-page-dots {
      bottom: -40px;
      @screen md {
        bottom: -80px;
      }
    }

    .flickity-prev-next-button .flickity-button-icon {
      left: 0;
      top: 0;
      height: 30px;
      width: 30px;
      @screen md {
        height: 50px;
        width: 50px;
      }
    }

    .flickity-page-dots li.dot {
      background-color: #DADADA;
      opacity: 1;
      width: 10px;
      height: 10px;
      @screen md {
        width: 19px;
        height: 19px;
      }
      &.is-selected {
        border-radius: 12.5px;
        width: 20px;
        height: 10px;
        @screen md {
          width: 38px;
          height: 19px;
        }
      }
    }
  }
}

.custom-white {
  .flickity-enabled {
    .flickity-button.flickity-prev-next-button {
      color: #A1ABB7;
    }
    .flickity-page-dots li.dot {
      &.is-selected {
        background-color: #92A1B3;
      }
    }
  }
}

.custom-dark {
  .flickity-viewport {
    min-height: auto !important;
    height: auto !important;
    position:relative;
    overflow: hidden;
    .flickity-slider {
      position: relative;
    }
  }
  .flickity-enabled {
    min-height: auto;
    margin-bottom: 0;
    overflow: visible;
    .flickity-button.flickity-prev-next-button {
      top: 156px;
      color: #43B02A;
      @screen md {
        top: 50%;
      }
    }
    .flickity-page-dots {
      position: relative !important;
      top: 0;
      li.dot {
        &.is-selected {
          background-color: #43B02A;
        }
      }
    }
  }
  .carousel-item {
    padding-bottom: 36px;
    &.is-selected {
      position: relative !important;
    }
    .image-placeholder {
      @screen md {
        height: 504px !important;
      }
    }
    img {
      max-height: 165px;
      object-fit: scale-down;
      @screen md {
        max-height: 504px;
      }
    }
  }
}

.custom-dark-review {
  .flickity-enabled {
    .flickity-button.flickity-prev-next-button {
      color: #43B02A;
    }
  }
  .flickity-viewport {
    min-height: auto !important;
    height: auto !important;
    position:relative;
    overflow: hidden;
    .flickity-slider {
      position: relative;
    }
  }
  .flickity-enabled {
    min-height: auto;
    margin-bottom: 0;
    overflow: visible;
    .flickity-page-dots {
      position: relative !important;
      top: 0;
      li.dot {
        background-color: #D4D4D4;
        opacity: 1;
      }
      li.dot.is-selected  {
        background-color: #43B02A;
      }
    }
  }
  .carousel-item {
    &.is-selected {
      position: relative !important;
    }
  }
}


.custom-carousel-modal {
  .flickity-enabled {
    .flickity-button.flickity-prev-next-button {
      bottom: -40px;
      height: 50px;
      width: 50px;
      @screen mx-sm {
        height: 30px;
        width: 30px;
      }
      &.previous {
        @screen md {
          left: -15px;
        }
        @screen sm {
          left: -40px;
        }
        left: -25px;
      }
      &.next {
        @screen md {
          right: -15px;
        }
        @screen sm {
          right: -40px;
        }
        right: -25px;

      }
      &.flickity-button-icon {
        top: -22%;
      }
    }
    .flickity-prev-next-button,
    .flickity-prev-next-button .previous,
    .flickity-prev-next-button .next,
    .flickity-prev-next-button:hover,
    .flickity-prev-next-button .previous:hover,
    .flickity-prev-next-button .next:hover {
      background-color: transparent;
    }
  }
  .flickity-viewport {
    min-height: auto !important;
    height: auto !important;
    position:relative;
    overflow: hidden;
    .flickity-slider {
      cursor: pointer;
      position: relative;
      div:not(.is-selected) {
        left: -100% !important;
      }
    }
  }
  
  .flickity-enabled {
    min-height: auto;
    margin-bottom: 0;
    overflow: visible;
    .flickity-page-dots {
      visibility: visible;
      @screen md {
        visibility: hidden;
      }
      bottom: -40px;
    }

    .flickity-page-dots li.dot {
      &.is-selected {
        border-radius: 6px;
        width: 24px;
        height: 12px;
      }
    }
  }
  .carousel-item {
    &.is-selected {
      position: relative !important;
    }
  }
}


//Custom SVG path for a thinner Chevron
.flickity-enabled .flickity-button.flickity-prev-next-button svg {
  @screen md {
    height: 20px;
  }
  path {
    d: path('M 10 50 L 60 100 L 66 94 L 21 50 L 66 6 L 60 0 Z')
  }
}

// Custom Color for the gift modal
.isGift {
  .flickity-enabled {
    .flickity-button.flickity-prev-next-button {
      color: $light-blue;
    }
  }
  .flickity-page-dots li.dot {
    background-color: $light-blue;
    &.is-selected {
      border-radius: 6px;
      width: 24px;
      height: 12px;
    }
  }
}

//

.isSystem {
  .flickity-enabled {
    .flickity-button.flickity-prev-next-button {
      color: $primary-green;
    }
  }
  .flickity-page-dots li.dot {
    background-color: $primary-green;
    &.is-selected {
      border-radius: 6px;
      width: 24px;
      height: 12px;
    }
  }
}
