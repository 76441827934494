.productModal {
    &-gallery {
        z-index: 90;
    }
    &-ribbon-wrapper {
            width: 120px;
            height: 120px;
            overflow: hidden;
            position: absolute;
            top: -3px;
            left: -3px;
        @screen md {
            width: 180px;
            height: 180px;
        }
    }
    &-ribbon {
        color: #333;
        text-align: center;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        position: relative;
        padding: 7px 0;
        top: 35px;
        left: -50px;
        width: 200px;
        background-color: #58A4B0;
        color: #fff;
        @screen md {
            padding: 17px 0;
            top: 55px;
            left: -76px;
            width: 300px;
        }
    }
}