.star {
    display: inline-block;
    width: 14px;
    height: 14px;

    &-smaller {
        width: 10px;
        height: 10px;
    }

    @screen md {
        width: 24px;
        height: 23px;
        &-smaller {
            width: 16px;
            height: 16px;
        }
        &-14 {
            width: 14px;
            height: 14px;
        }
    }
    background-repeat: no-repeat;
    background-size: contain;
    &-whole {
        background-image: url('/images/Star.svg');
        &-white {
            background-image: none;
            mask-image: url('/images/Star.svg');
            mask-size: cover;
            @apply bg-white;
        }
    }
    &-half {
        background-image: url('/images/HalfStar.svg');
        &-white {
            background-image: none;
            mask-image: url('/images/HalfStar.svg');
            mask-size: cover;
            @apply bg-white;
        }
    }
    &-empty {
        background-image: url('/images/EmptyStar.svg');
        &-white {
            background-image: none;
            mask-image: url('/images/EmptyStar.svg');
            mask-size: cover;
            @apply bg-white;
        }
    }
    &::after {
        content: '';
        width: 15px;
        height: 15px;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
